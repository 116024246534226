import * as React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { rhythm } from '../utils/typography';
import { SocialQuery } from '../../types/graphql-types';

const externalLinkProps = {
  target: '_blank',
  rel: 'nofollow noopener noreferrer',
};

const IconLink: React.FC<{ to: string; icon: IconProp; size: string }> = ({ to, icon, size }) => {
  const InitialLink = styled(Link)`
    font-size: ${size};
    &:after,
    &:hover:after {
      content: '' !important;
    }
  `;
  return (
    <InitialLink {...externalLinkProps} to={to}>
      <FontAwesomeIcon icon={icon} fixedWidth />
    </InitialLink>
  );
};

const Social: React.FC<{ size?: string }> = ({ size = rhythm(1) }) => {
  const data = useStaticQuery<SocialQuery>(socialQuery);
  const { social } = data.site.siteMetadata;
  return (
    <div>
      <IconLink size={size} to={`https://twitter.com/${social.twitter}`} icon={faTwitter} />
      <IconLink size={size} to={`https://github.com/${social.github}`} icon={faGithub} />
      <IconLink size={size} to={`https://linkedin.com/in/${social.linkedin}`} icon={faLinkedin} />
    </div>
  );
};

export default Social;

const socialQuery = graphql`
  query Social {
    site {
      siteMetadata {
        social {
          twitter
          github
          linkedin
        }
      }
    }
  }
`;
